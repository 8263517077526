<template>
    <budget-reports-card-set :colClass="'mb-3'"></budget-reports-card-set>
</template>

<script>
import BudgetReportsCardSet from '../ReportCards/BudgetReportsCardSet.vue'
import titleMixins from '@/mixins/title'

export default {
  name: 'BudgetReportsTab',
  mixins: [titleMixins],
  components: { BudgetReportsCardSet },
  data () {
    return {}
  },
  computed: {
    titleMeta () {
      return this.$t('reports._common.budget_reports')
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">

</style>
