<template>
<b-row :class="rowClass">
  <b-col lg="4" md="6" sm="12" :class="colClass">
    <report-card
      :title="$t(translationPath + 'performance_budget')"
      :description="$t(translationPath + 'performance_budget_description')"
      :btnCaption="$t(translationPath + 'performance_budget_open')"
      :btnUrl="'/reports/budget/performance/'"
      :imgUrl="require('@/assets/img/budget-report.png')"
      :imgAlt="$t(translationPath + 'performance_budget')"
    ></report-card>
  </b-col>
</b-row>
</template>

<script>
import ReportCard from './ReportCard.vue'

export default {
  name: 'ReportCardSetBudget',
  props: {
    rowClass: {
      type: String,
      required: false,
      default: 'main-content-wrapper pt-0 pb-0 mt-0'
    },
    colClass: {
      type: String,
      required: false,
      default: 'mb-5'
    }
  },
  components: { ReportCard },
  data () {
    return {
      translationPath: 'reports.budget._common.'
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/reports-cards.scss';
</style>
