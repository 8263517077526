<template>
   <performance-report-card-set :colClass="'mb-3'"></performance-report-card-set>
</template>

<script>
import PerformanceReportCardSet from '../ReportCards/PerformanceReportsCardSet.vue'
import titleMixins from '@/mixins/title'

export default {
  name: 'PerformanceReportsTab',
  mixins: [titleMixins],
  components: { PerformanceReportCardSet },
  computed: {
    titleMeta () {
      return this.$t('reports._common.performance_reports')
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>
