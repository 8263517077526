<template>
  <b-card class="mb-2 depth-1 reports_cards_main">
    <div slot="header" class="text-center p-2">
      <b-link :to="btnUrl">
        <b-img v-if="imgUrl" fluid :src="imgUrl" :alt="imgAlt" />
        <i v-else class="flaticon import-document stroke text-primary" style="font-size: 48px;font-weight: 200"></i>
      </b-link>
    </div>
    <b-card-title class="text-left">
      {{ title }}
    </b-card-title>
    <b-card-text>
      {{ description }}
    </b-card-text>
    <div slot="footer">
      <b-button class="btn btn-sm font-weight-bold d-block" :to="btnUrl">
        {{ btnCaption }} <i class="flaticon stroke right-2"></i>
      </b-button>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'ReportCard',
  props: ['title', 'description', 'btnCaption', 'btnUrl', 'imgUrl', 'imgAlt']
}
</script>

<style lang="scss" scoped>
.reports_cards {
  .card-body {
    min-height:134px;
  }
}
</style>
