<template>
  <div class="chart-menu-container">
    <b-row no-gutters>
      <b-col>
        <h1>{{$t('reports._common.reports_insights')}}</h1>
      </b-col>
    </b-row>
    <b-row class="submenu-container dark justify-content-left" no-gutters>
      <b-col md="auto">
        <b-link tab="performanceReports" to="/sandbox/performance" :class="{disabled: activeTab == 'performanceReports'}">
          {{$t('reports._common.performance_reports')}}
        </b-link>
      </b-col>
      <b-col md="auto">
        <b-link tab="balanceReports" to="/sandbox/balance" :class="{disabled: activeTab == 'balanceReports'}">
          {{$t('reports._common.balance_reports')}}
        </b-link>
      </b-col>
      <b-col md="auto">
        <b-link tab="balanceReports" to="/sandbox/budget" :class="{disabled: activeTab == 'budgetReports'}">
          {{$t('reports._common.budget_reports')}}
        </b-link>
      </b-col>
      <b-col md="auto">
        <b-link tab="otherReports" to="/sandbox/other" :class="{disabled: activeTab == 'otherReports'}">
          {{$t('reports._common.other_reports')}}
        </b-link>
      </b-col>
      <b-col md="auto">
        <b-link tab="simulatorReports" to="/sandbox/simulators" :class="{disabled: activeTab == 'simulatorReports'}">
          {{$t('reports._common.simulator_reports')}}
        </b-link>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col no-gutters>
        <hr class="site-divider" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ReportsSubmenuHeader',
  props: {
    active: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      activeTab: null
    }
  },
  created () {
    this.activeTab = this.active
  },
  /* methods: {
    onTabClick (event) {
      this.activeTab = event.target.attributes.tab.value
      this.$emit('tab-switched', event.target.attributes.tab.value)
    }
  }, */
  watch: {
    active () {
      this.activeTab = this.active
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
</style>
