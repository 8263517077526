<template>
<b-row id="simulatorReport" :class="rowClass">
  <b-col lg="4" md="6" sm="12" :class="colClass">
    <report-card
      :title="$t(translationPath + 'pension_simulator')"
      :description="$t(translationPath + 'pension_simulator_description')"
      :btnCaption="$t(translationPath + 'pension_simulator_open')"
      :btnUrl="'/simulators/pension'"
      :imgUrl="require('@/assets/img/pension-simulator.png')"
      :imgAlt="$t(translationPath + 'pension_simulator')"
    ></report-card>
  </b-col>
  <b-col lg="4" md="6" sm="12" :class="colClass">
    <report-card
      :title="$t(translationPath + 'compounded_interest_simulator')"
      :description="$t(translationPath + 'compounded_interest_simulator_description')"
      :btnCaption="$t(translationPath + 'compounded_interest_simulator_open')"
      :btnUrl="'/simulators/compounded-interest'"
      :imgUrl="require('@/assets/img/compound-interest.png')"
      :imgAlt="$t(translationPath + 'compounded_interest_simulator')"
    ></report-card>
  </b-col>
</b-row>
</template>

<script>
import ReportCard from './ReportCard.vue'

export default {
  name: 'ReportCardSimulator',
  props: {
    rowClass: {
      type: String,
      required: false,
      default: 'main-content-wrapper pt-0 pb-0 mt-0'
    },
    colClass: {
      type: String,
      required: false,
      default: 'mb-5'
    }
  },
  components: { ReportCard },
  data () {
    return {
      translationPath: 'reports.simulators._common.'
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/reports-cards.scss';
#simulatorReport {
  .card-body {
    @media screen and (min-width: 767px) and (max-width:991px) {
      min-height: 194px;
    }
    @media screen and (min-width: 991px) and (max-width:1168px) {
      min-height: 244px;
    }
    @media screen and (min-width: 1168px) {
      min-height: 169px;
    }
  }
}
</style>
