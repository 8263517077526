<template>
   <balance-reports-card-set></balance-reports-card-set>
</template>

<script>
import BalanceReportsCardSet from '../ReportCards/BalanceReportsCardSet.vue'
import titleMixins from '@/mixins/title'

export default {
  name: 'BalanceReportsTab',
  mixins: [titleMixins],
  components: { BalanceReportsCardSet },
  computed: {
    titleMeta () {
      return this.$t('reports._common.balance_reports')
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">

</style>
