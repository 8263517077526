<template>
<b-row :class="rowClass" class="performanceReportMenu">
  <b-col lg="4" md="6" sm="12" :class="colClass">
    <report-card
      :title="$t(translationPath + '12month_performance_report')"
      :description="$t(translationPath + '12month_performance_report_description')"
      :btnCaption="$t(translationPath + '12month_performance_report_open')"
      :btnUrl="'/reports/performance/12-month-result/'"
      :imgUrl="require('@/assets/img/performance-report-12months.png')"
      :imgAlt="$t(translationPath + '12month_performance_report')"
    ></report-card>
  </b-col>

  <b-col lg="4" md="6" sm="12" :class="colClass">
    <report-card
      :title="$t(translationPath + 'performance_report')"
      :description="$t(translationPath + 'performance_report_description')"
      :btnCaption="$t(translationPath + 'performance_report_open')"
      :btnUrl="'/reports/performance/result/'"
      :imgUrl="require('@/assets/img/performance-report.png')"
      :imgAlt="$t(translationPath + 'performance_report')"
    ></report-card>
  </b-col>

  <b-col lg="4" md="6" sm="12" :class="colClass">
    <report-card
      :title="$t(translationPath + 'active_passive_report')"
      :description="$t(translationPath + 'active_passive_report_description')"
      :btnCaption="$t(translationPath + 'active_passive_report_open')"
      :btnUrl="'/reports/performance/active-passive/'"
      :imgUrl="require('@/assets/img/reports-active-passive.png')"
      :imgAlt="$t(translationPath + 'active_passive_report')"
    ></report-card>
  </b-col>

  <b-col lg="4" md="6" sm="12" :class="colClass">
    <report-card
      :title="$t(translationPath + 'savings_ratio_report')"
      :description="$t(translationPath + 'savings_ratio_report_description')"
      :btnCaption="$t(translationPath + 'savings_ratio_report_open')"
      :btnUrl="'/reports/performance/savings-ratio/'"
      :imgUrl="require('@/assets/img/savings-ratio.png')"
      :imgAlt="$t(translationPath + 'savings_ratio_report')"
    ></report-card>
  </b-col>

  <b-col lg="4" md="6" sm="12" :class="colClass">
    <report-card
      :title="$t(translationPath + 'financial_freedom_report')"
      :description="$t(translationPath + 'financial_freedom_report_description')"
      :btnCaption="$t(translationPath + 'financial_freedom_report_open')"
      :btnUrl="'/reports/performance/financial-freedom'"
      :imgUrl="require('@/assets/img/financial-freedom.png')"
      :imgAlt="$t(translationPath + 'financial_freedom_report')"
    ></report-card>
  </b-col>

  <b-col lg="4" md="6" sm="12" :class="colClass">
    <report-card
      :title="$t(translationPath + 'financial_security_report')"
      :description="$t(translationPath + 'financial_security_report_description')"
      :btnCaption="$t(translationPath + 'financial_security_report_open')"
      :btnUrl="'/reports/performance/financial-security'"
      :imgUrl="require('@/assets/img/financial-security.png')"
      :imgAlt="$t(translationPath + 'financial_security_report')"
    ></report-card>
  </b-col>

  <b-col lg="4" md="6" sm="12" :class="colClass">
    <report-card
      :title="$t(translationPath + 'split_expenses_report')"
      :description="$t(translationPath + 'split_expenses_report_description')"
      :btnCaption="$t(translationPath + 'split_expenses_report_open')"
      :btnUrl="'/reports/performance/split-expenses'"
      :imgUrl="require('@/assets/img/report-split-expenses.png')"
      :imgAlt="$t(translationPath + 'split_expenses_report')"
    ></report-card>
  </b-col>
</b-row>
</template>

<script>
import ReportCard from './ReportCard.vue'

export default {
  name: 'ReportCardSetIncomeExpence',
  props: {
    rowClass: {
      type: String,
      required: false,
      default: 'main-content-wrapper pt-0 pb-0 mt-0'
    },
    colClass: {
      type: String,
      required: false,
      default: 'mb-5'
    }
  },
  components: { ReportCard },
  data () {
    return {
      translationPath: 'reports.performance._common.'
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/reports-cards.scss';
</style>
