<template>
    <other-reports-card-set :colClass="'mb-3'"></other-reports-card-set>
</template>

<script>
import OtherReportsCardSet from '../ReportCards/OtherReportsCardSet.vue'
import titleMixins from '@/mixins/title'

export default {
  name: 'OtherReportsTab',
  mixins: [titleMixins],
  components: { OtherReportsCardSet },
  data () {
    return {}
  },
  computed: {
    titleMeta () {
      return this.$t('reports._common.other_reports')
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">

</style>
