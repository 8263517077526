<template>
    <simulator-reports-card-set :colClass="'mb-3'"></simulator-reports-card-set>
</template>

<script>
import SimulatorReportsCardSet from '../ReportCards/SimulatorReportsCardSet.vue'
import titleMixins from '@/mixins/title'

export default {
  name: 'SimulatorReportsTab',
  mixins: [titleMixins],
  components: { SimulatorReportsCardSet },
  data () {
    return {}
  },
  computed: {
    titleMeta () {
      return this.$t('reports._common.simulator_reports')
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">

</style>
