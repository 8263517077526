<template>
  <b-row :class="rowClass">
    <b-col lg="4" md="6" sm="12" :class="colClass">
      <report-card
        :title="$t(translationPath + 'balance_report')"
        :description="$t(translationPath + 'balance_report_description')"
        :btnCaption="$t(translationPath + 'balance_report_open')"
        :btnUrl="'/reports/balance/report'"
        :imgUrl="require('@/assets/img/balance-sheet.png')"
        :imgAlt="$t(translationPath + 'balance_report')"
      ></report-card>
    </b-col>

    <b-col lg="4" md="6" sm="12" :class="colClass">
      <report-card
        :title="$t(translationPath + 'four_bucket_report')"
        :description="$t(translationPath + 'four_bucket_report_description')"
        :btnCaption="$t(translationPath + 'four_bucket_report_open')"
        :btnUrl="'/reports/balance/four-buckets/'"
        :imgUrl="require('@/assets/img/four-bucket.png')"
        :imgAlt="$t(translationPath + 'four_bucket_report')"
      ></report-card>
    </b-col>

    <b-col lg="4" md="6" sm="12" :class="colClass">
      <report-card
        :title="$t(translationPath + 'subtypes_report')"
        :description="$t(translationPath + 'subtypes_report_description')"
        :btnCaption="$t(translationPath + 'subtypes_report_open')"
        :btnUrl="'/reports/balance/subtypes/'"
        :imgUrl="require('@/assets/img/balance-subtypes.png')"
        :imgAlt="$t(translationPath + 'subtypes_report')"
      ></report-card>
    </b-col>

    <b-col lg="4" md="6" sm="12" :class="colClass">
      <report-card
        :title="$t(translationPath + 'asset_allocation_report')"
        :description="$t(translationPath + 'asset_allocation_report_description')"
        :btnCaption="$t(translationPath + 'asset_allocation_report_open')"
        :imgUrl="require('@/assets/img/balance-asset-classes.png')"
        :btnUrl="'/reports/balance/asset-allocation/'"
      ></report-card>
    </b-col>
  </b-row>
</template>

<script>
import ReportCard from './ReportCard.vue'

export default {
  name: 'ReportCardSetAssetLiabilty',
  props: {
    rowClass: {
      type: String,
      required: false,
      default: 'main-content-wrapper pt-0 mt-0'
    },
    colClass: {
      type: String,
      required: false,
      default: 'mb-5'
    }
  },
  components: { ReportCard },
  data () {
    return {
      translationPath: 'reports.balance._common.'
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/reports-cards.scss';
</style>
